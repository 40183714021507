import { Component, HostListener, Input } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { Defaultconfig } from 'src/app/shared/models/Defaultconfig';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() height: any;

  constructor(private communicatorService: CommunicationService) { }

  ngOnInit() {
  }
}
