import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { AppsComponent } from './components/apps/apps.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { WorkflowsComponent } from './components/workflows/workflows.component';

const routes: Routes = [
  { path: '', redirectTo: 'load', pathMatch: 'full' },
  { path: 'load', component: LoaderComponent },
  /** login */
  { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  /** register flow */
  { path: 'register', loadChildren: () => import('./components/register/register.module').then(m => m.RegisterModule) },
  { path: 'register-success', loadChildren: () => import('./components/register-success/register-success.module').then(m => m.RegisterSuccessModule) },
  { path: 'pattern', loadChildren: () => import('./components/pattern/pattern.module').then(m => m.PatternModule) },
  { path: 'push', loadChildren: () => import('./components/push/push.module').then(m => m.PushModule) },
  { path: 'verification-success', loadChildren: () => import('./components/verification-success/verification-success.module').then(m => m.VerificationSuccessModule) },
  /** forgot password flow start */
  { path: 'forgot-password', loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'forgot_password_verification_sent', loadChildren: () => import('./components/forgot-password-link/forgot-password-link.module').then(m => m.ForgotPasswordLinkModule) },
  { path: 'reset_password_with_forgot_pwd_link', loadChildren: () => import('./components/reset-password-link/reset-password-link.module').then(m => m.ResetPasswordLinkModule) },
  { path: 'reset_success', loadChildren: () => import('./components/reset-success/reset-success.module').then(m => m.ResetSuccessModule) },
  /** 2FA verification */
  { path: '2fa', loadChildren: () => import('./components/mfainitiate/mfainitiate.module').then(m => m.MfainitiateModule) },
  { path: 'otp-verification', loadChildren: () => import('./components/otp-email/otp-email.module').then(m => m.OtpEmailModule) },
  /** Profile */
  { path: 'profile', title: 'Profile', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
  /** Consent */
  { path: 'consent-info', loadChildren: () => import('./components/consent/consent.module').then(m => m.ConsentModule) },
  { path: 'ad-login', loadChildren: () => import('./components/ad-login/ad-login.module').then(m => m.AdLoginModule) },
  /** Progressive registration */
  { path: 'register_collect_info', loadChildren: () => import('./components/progressive-register/progressive-register.module').then(m => m.ProgressiveRegisterModule) },
  { path: 'social_register_collect_info', loadChildren: () => import('./components/social-reg-info/social-reg-info.module').then(m => m.SocialRegInfoModule) },
  { path: 'success', loadChildren: () => import('./components/progressive-success/progressive-success.module').then(m => m.ProgressiveSuccessModule) },
  { path: 'federation-login', loadChildren: () => import('./components/federation-login/federation-login.module').then(m => m.FederationLoginModule) },
  { path: 'passwordless', loadChildren: () => import('./components/passwordless-login/passwordless-login.module').then(m => m.PasswordlessLoginModule) },
  { path: 'unauthorized', loadChildren: () => import('./components/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule) },
  { path: 'single-legged', loadChildren: () => import('./components/single-legged/single-legged.module').then(m => m.SingleLeggedModule) },
  { path: 'user_invite_request_error', loadChildren: () => import('./components/invite-user/invite-user.module').then(m => m.InviteUserModule) },
  { path: 'face-recog', loadChildren: () => import('./components/face-recog/face-recog.module').then(m => m.FaceRecogModule) },
  { path: 'apps', component: AppsComponent, loadChildren: () => import('./components/apps/apps.module').then(m => m.AppsModule) },
  { path: 'workflow', loadChildren: () => import('./components/workflows/workflows.module').then(m => m.WorkflowsModule) },
  { path: 'workflow-initiate', loadChildren: () => import('./components/workflow-initiate/workflow-initiate.module').then(m => m.WorkflowInitiateModule) },
  { path: 'mfa-enroll', loadChildren: () => import('./components/mfa-enroll/mfa-enroll.module').then(m => m.MfaEnrollModule) },
  // ciba-verification flow
  { path: 'ciba-verify', loadChildren: () => import('./components/ciba-code-flow/ciba-code/ciba-code.module').then(m => m.CibaCodeModule) },
  { path: 'ciba-success', loadChildren: () => import('./components/ciba-code-success/ciba-code-success/ciba-code-success.module').then(m => m.CibaCodeSuccessModule) },
  // { path: 'single-legged', loadChildren: () => import('./components/single-legged/single-legged.module').then(m => m.SingleLeggedModule) },
  /** Last - page not found */
  { path: '**', loadChildren: () => import('./components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
