export class Defaultconfig {
  public static GetAuthBaseURL(): string {
    const current_location = location.host;
    const { host, hostname, protocol, port } = location;
    const modifiedHostname = hostname.includes('portal-sso-') ? hostname.replace('portal-sso-', '') : hostname;
    const modifiedDomain = `${modifiedHostname.split('.')[0]}-api.${modifiedHostname.split('.').slice(1).join('.')}`;
    const modifiedUrl = `${protocol}//${modifiedDomain}${port ? `:${port}` : ''}/`;
    if (current_location.includes('localhost') || current_location.includes('nightly-accounts'))
      return `https://nightly-accounts-api.complyment.com/`;
    else
      return modifiedUrl;
  }

  // https://portal-sso-nightly-accounts.complyment.com/

  public static getCurrentUrl(): string {
    const protocol = location.protocol;
    const current_location = location.host;
    const portal_url = current_location.split('.');
    return protocol + '//' + portal_url.join('.');
  }

  public static REGISTER_FIELDS_URL = "public-srv/registration/settings/by/requestid/";
  public static LOGIN_INFO_URL = "public-srv/login/settings/by/requestid/";
  public static VERIFICATION_SRV = "verification-srv/forgotpassword/"
  public static USERS_SRV = "users-srv/users/";
  public static INVITE_SRV() {
    return this.GetAuthBaseURL() + this.USERS_SRV + "invite/info/public/";
  }

  public static checkHostListenerEvent(event: any): any {
    if (event.currentTarget) {
      if (event.currentTarget.innerWidth <= 767) {
        return 'mobile';
      } else {
        return 'desktop';
      }
    } else {
      this.checkWidth();
    }
  }

  public static checkWidth(): string {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width <= 767) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
}
