<div class="container-fluid" *ngIf="height">
  <div class="row text-center justify-content-center align-items-center p-2">
    <div class="spinner-border spinner-clr" role="status">
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="!height">
  <div class="row text-center justify-content-center align-items-center p-2 min-vh-100">
    <div class="spinner-border spinner-clr" role="status">
    </div>
  </div>
</div>