import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OutgoingInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      setHeaders: {
        // 'content-type': "application/json",
        'X-Content-Type-Options': "nosniff",
        'Strict-Transport-Security': "max-age=63072000; includeSubDomains; preload",
        'Content-Security-Policy': "script-src https: 'unsafe-inline' 'unsafe-eval'; style- src https: 'unsafe-inline' 'unsafe-eval';img- src https: data:;font - src https: data: ; ",
        'Permissions-Policy': "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*"
      },
    });

    return next.handle(modifiedRequest);
  }
}
