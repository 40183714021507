import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { CookieStore } from 'src/app/shared/helpers/CookieStore';
import { Utils } from 'src/app/shared/helpers/Utils';
import { Defaultconfig } from 'src/app/shared/models/Defaultconfig';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent {

  sideMenu: any[] = [
    { name: 'Dashboard', url: '', active: true, id: 'apps' }
  ];
  loader: boolean = false;
  token: any;
  resIds: any;
  redirectUrl: any;
  logoUrl: any;
  viewType: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.viewType = Defaultconfig.checkHostListenerEvent(event);
  }
  apps: any[] = [];
  providerSettings: any;
  userInfo: any;
  temp: any;
  userName: any;
  appList: any[] = [];
  appsList: any[] = [];

  constructor(private communicationService: CommunicationService, private authService: AuthService, private router: Router) {
    this.communicationService.queryParams$.subscribe((data: any) => {
      //console.log('Login params =', data);
      this.token = data.access_token;
      CookieStore.saveBearerToken(data.access_token);
      //console.log('token', this.token);
      if (this.token && this.token != '') {
        this.getUserInfo();
      }
    });
    this.communicationService.providerSettings$.subscribe((data: any) => {
      this.providerSettings = data;
      //console.log('data', this.providerSettings);
      this.apps = this.providerSettings.apps;
      if (this.apps && this.apps.length) {
        this.apps.forEach((res: any) => {
          res['image'] = Utils.getLogo(res.type);
        });
      }
    });
    this.router.events.subscribe((value: any) => {
      // //console.log('current route: ', this.router.url.toString().split('?')[0], this.menuItems);
      this.sideMenu.forEach((menu: any) => {
        if (this.router.url.toString().split('?')[0].includes(menu.id)) {
          menu.active = true;
        } else {
          menu.active = false;
        }
      });
      this.communicationService.queryParams$.subscribe((data: any) => {
        //console.log('Login params =', data);
        this.token = data.access_token;
        //console.log('token', this.token);
      });
    });
  }

  ngOnInit() {
    this.getViewType(); //Responsive
    let $button: any = document.querySelector('#sidebar-toggle');
    let $wrapper: any = document.querySelector('#wrapper');
    if ($button) {
      $button.addEventListener('click', (e: any) => {
        e.preventDefault();
        $wrapper.classList.toggle('toggled');
      });
    }
    this.getAllIds();
  }

  getViewType() {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width <= 767) {
      this.viewType = 'mobile';
    } else {
      this.viewType = 'desktop';
    }
    //console.log('type', this.viewType);
  }

  getAllIds() {
    this.loader = true;
    this.authService.getIdsForLogout(this.token).subscribe((res: any) => {
      //console.log('Ids', res);
      if (res && res.data) {
        this.loader = false;
        this.resIds = res.data;
        this.communicationService.setProviderSettings(res.data);
        // this.communicationService.broadcast('providerSettings', res.data);
        this.logoUrl = this.resIds.display_settings ? this.resIds.display_settings.logo_uri : '';
        const macRef = localStorage.getItem('x_t_nbd_ref');
        this.redirectUrl = Defaultconfig.getCurrentUrl() + '/login?view_type=login&request_id=' + this.resIds.request_id + '&client_id=' + this.resIds.client_id + '&flow_id=' + this.resIds.flow_id + '&x_t_nbd_ref=' + macRef;
        //console.log('url', this.redirectUrl);
        localStorage.setItem('post_url', this.redirectUrl);
      }
    });
  }

  logout() {
    this.authService.logout(this.token, encodeURIComponent(this.redirectUrl)).subscribe((res: any) => {
      //console.log(res);
    });
    window.open(this.redirectUrl, '_self');
  }

  redirect(url: any) {
    window.open(url, '_blank');
  }


  getUserInfo() {
    this.authService.getUserInfo().subscribe((res: any) => {
      if (res) {
      this.temp = { ...res };
      this.userInfo = res ? res : '';
      this.userName = this.userInfo.name ? this.userInfo.name.substring(0, 1) : this.userInfo.email.substring(0, 1);
      this.userInfo.updated_at = new Date(this.userInfo.updated_at * 1000);
      this.userInfo.created_at = new Date(this.userInfo.created_at * 1000);
      //console.log('date', this.userInfo);
      const hashedKey = CryptoJS.MD5(this.token).toString();
      const encryptedUserInfo = this.encData(JSON.stringify(res), hashedKey);
      localStorage.setItem('userInfo', encryptedUserInfo);
      // localStorage.setItem('userInfo', JSON.stringify(res));
      // if (this.userInfo != '') {
      //   // this.getRegistrationFields();
      //   this.getAllIds();
      // }
      this.getAllIds();
      }else {
        this.userInfo = '';
      }
    }, error => {
      console.error('Error fetching user info:', error);
    });
  }
  encData(value: any, key: string) {
    var ciphertext = CryptoJS.AES.encrypt(value, key).toString();
    return ciphertext;
  }
}
