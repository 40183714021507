import { Injectable, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })

export class ToastService {
  toastOptions: any;
  constructor(private toastr: ToastrService) {
    this.toastOptions = {
      timeOut: 2000,
      closeButton: true,
      positionClass: 'toast-top-right',
      opacity: 1,
    };
  }
  toasts: any[] = [];

  success(title: any, msg?: any) {
    this.toastr.success(msg ? msg : '', title, this.toastOptions);
  }

  error(title: any, msg: any) {
    this.toastr.error(msg, title, this.toastOptions);
  }

  info(title: any, msg: any) {
    this.toastr.info(msg, title, this.toastOptions);
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
