import { NgModule } from '@angular/core';
import { ToastsContainer } from './toasts-container.component';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [NgbToastModule, NgIf, NgTemplateOutlet, NgFor],
  declarations: [ToastsContainer],
  exports: [ToastsContainer],
})
export class ToastsContainerModule {}
