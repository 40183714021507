import { Injectable } from '@angular/core';
import { Defaultconfig } from '../shared/models/Defaultconfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieStore } from '../shared/helpers/CookieStore';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private titleService: Title) { }

  private headers: HttpHeaders = new HttpHeaders().set('Authorization', `${'Bearer ' + localStorage.getItem('access_token')}`);

  private accessToken?: string;

  // Method to set the access token
  setAccessToken(token: string) {
    this.accessToken = token;
  }

  // Method to get the access token
  getAccessToken(): string | undefined {
    return this.accessToken;
  }

  private addCommonQueryToUrl(url: string): string {
    const macRef = localStorage.getItem('x_t_nbd_ref');
    return macRef ? `${url}?x_t_nbd_ref=${macRef}` : url;
  }

  private getHeaders(): HttpHeaders {
    const macRef = localStorage.getItem('x_t_nbd_ref');
    let headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    if (macRef) {
      headers = headers.set('x-t-nbd-ref', macRef);
    }
    return headers;
  }

  private getHeadersWithToken(access_token: string): HttpHeaders {
    const macRef = localStorage.getItem('x_t_nbd_ref');
    
    // Set the Authorization header with the provided token
    let headers = new HttpHeaders().set('Authorization', `Bearer ${access_token}`);
    
    // Add custom header if available in localStorage
    if (macRef) {
      headers = headers.set('x-t-nbd-ref', macRef);
    }
    
    return headers;
  }
  

  getDynamicTitle() {
    return 'current title :' + this.titleService.getTitle();
  }

  setDynamicTitle(title: string) {
    this.titleService.setTitle(title);
  }

  getPublicLoginInfo(requestId: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/login/settings/by/requestid/' + requestId;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  loginWithEncCreds(data: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'login-srv/login';
    return this.http.post(url, data);
  }

  initiateForgetPassword(payload: any): Promise<any> {
    const url = Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() }).toPromise();
  }

  resetPasssword(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/data/reset';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  resetVerificationCode(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + Defaultconfig.VERIFICATION_SRV + 'user/verification';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getRegistrationFields(requestId: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/registration/settings/by/requestid/' + requestId;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  register(registrationDetails: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/register';
    return this.http.post(url, registrationDetails);
  }

  registerv2(registrationDetails: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/v2/users/register';
    return this.http.post(url, registrationDetails, { headers: this.getHeaders() });
  }

  registerv2Progressive(registrationDetails: any, track_id: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/v2/users/register?track_id=' + `${track_id}`;
    return this.http.post(url, registrationDetails, { headers: this.getHeaders() });
  }

  resetPassword(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/changepassword';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getnvitedUserInfo(trackId: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/invite/info/public/' + trackId;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  MFAVerifiedList(pay: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/verified/list';
    return this.http.post(url, pay, { headers: this.getHeaders() });
  }

  MFAUnVerifiedList(pay: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/unverified/list';
    return this.http.post(url, pay, { headers: this.getHeaders() });
  }
  MFAUnVerifiedWithTokenList(token: string, pay: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + `verification-srv/v2/verification/setup/unverified/list?access_token=${token}`;
    const headers = this.getHeadersWithToken(token);
    return this.http.post(url, pay, { headers: headers });
  }

  MFAEnabledListByAdmin(): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification';
    return this.http.get(url, { headers: this.getHeaders() });
  }

  getUserInfo(): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/userinfo';
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  getRegField(): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'registrationfields-srv/fields/util/enabled/list';
    return this.http.get(url, { headers: this.getHeaders() });
  }

  updateUserInfo(payload: any, sub: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/update/' + sub;
    return this.http.put(url, payload, { headers: this.getHeaders() });
  }

  getBackupCode(sub: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/user-backup-code?sub=' + sub;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  deleteMfaType(userVerificationSetupId: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/deleteUserVerificationSetupById/' + userVerificationSetupId;
    return this.http.delete(url, { headers: this.getHeaders() });
  }

  revokeAllToken(sub: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'token-srv/revokealltoken/' + sub;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  getIdsForLogout(token: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + `public-srv/registration/provider/settings/by/token?access_token=${token}&filter_type=usage`;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  logoutv2(saved_token: any, post_logout_redirect_uri: any): void {
    const logout_base_url = Defaultconfig.GetAuthBaseURL() + 'token-srv/end_session';
    window.location.href =
      logout_base_url + '?post_logout_redirect_uri=' + post_logout_redirect_uri + '&access_token_hint=' + saved_token;
  }

  logout(token: any, post_url: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'token-srv/end_session?access_token_hint=' + token + '&post_logout_redirect_uri=' + post_url;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  getConsentDetails(track_id: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/precheck/' + `${track_id}`;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  acceptConsent(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/consent/usage/accept';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  consentContinue(track_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    const url = Defaultconfig.GetAuthBaseURL() + 'login-srv/continue/' + `${track_id}`;
    return this.http.post(url, {}, { headers });
  }

  verifyStatus(track_id: any): Promise<any> {
    const url = Defaultconfig.GetAuthBaseURL() + `verification-srv/v2/verification/usage/status/${track_id}`;
    return this.http.post(url, {}, { headers: this.getHeaders() }).toPromise();
  }

  verifiedList(payload: any): Promise<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/verified/list';
    return this.http.post(url, payload, { headers: this.getHeaders() }).toPromise();
  }

  initiateOTP(payload: any): Promise<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() }).toPromise();
  }

  validateOTP(payload: any): Promise<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() }).toPromise();
  }

  // MFA APIs
  reinitiateOTP(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/reinitiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  reinitiateUsageOTP(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/reinitiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  initiateMFA(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  initiateMandatoryMFA(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }
  initiateMandatoryMFAWithToken(token: string, payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/initiate';
    const headers = this.getHeadersWithToken(token);
    return this.http.post(url, payload, { headers: headers });
  }

  verifyOtpV2(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getMfaStatus(verification_usage_id: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/mfastatus/' + verification_usage_id;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  verifyOtp(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }
  configureFido(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/initiate/fido';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  // User recent activity
  userRecentActivity(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'reports-v2-srv/user-reports/util/useractivity';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getProgressiveRegisterInformation(track_id: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/precheck/' + `${track_id}`;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  progressiveRegister(payload: any, track_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/register?track_id=' + `${track_id}`;
    return this.http.post(url, payload, { headers });
  }

  getSocialRegisterInformation(social_track_id: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'social-login-srv/userinfo/' + `${social_track_id}`;
    return this.http.post(url, {}, { headers: this.getHeaders() });
  }

  socialRegister(payload: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    const url = Defaultconfig.GetAuthBaseURL() + 'users-srv/users/social/register';
    return this.http.post(url, payload, { headers }).toPromise();
  }

  federationLogin(payload: any, reqest_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/login/federation/' + reqest_id;
    return this.http.post(url, payload, { headers });
  }

  // FIDO APIs
  initiateFidoSetup(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  initiateFidoSetupv2(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  registerFidoSetup(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  registerFidoSetupv2(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  initiateFidoUsage(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/initiate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  registerFidoUsage(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/otp';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getUsersDeviceDetails(): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/fido';
    return this.http.get(url, { headers: this.getHeaders() });
  }

  deleteFidoDevice(device_id: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/fido/' + device_id;
    return this.http.delete(url, { headers: this.getHeaders() });
  }

  // Initiate webcam face recognition
  initiateFaceMFA(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'mfa-srv/register/initate';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  // Upload face
  uploadFace(formData: any, verification_usage_id: any, usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    headers.append('content-type', 'multipart/form-data');
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/setup/validate/face?verification_usage_id=' + `${verification_usage_id}` + '&usage_id=' + `${usage_id}`;
    return this.http.post(url, formData, { headers });
  }
  
  uploadFaceV2(formData: any, verification_usage_id: any, usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    headers.append('content-type', 'multipart/form-data');
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-public-srv/v2/verification/setup/validate/face?verification_usage_id=' + `${verification_usage_id}` + '&usage_id=' + `${usage_id}`;
    return this.http.post(url, formData, { headers });
  }
  
  verifyFace(formData: any, verification_usage_id: any, usage_id: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    headers.append('content-type', 'multipart/form-data');
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/v2/verification/usage/validate/face?verification_usage_id=' + `${verification_usage_id}` + '&usage_id=' + `${usage_id}`;
    return this.http.post(url, formData, { headers });
  }
  
  // Scope user consent
  acceptScopeConsent(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/consent/usage/accept/scope/user';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  userConsentActivity(sub: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'scopes-srv/scope/util/find/scope/user/consent/status/' + sub;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  revokeConsent(sub: any, payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'scopes-srv/scope/util/scope/user/consent/status/update/' + sub;
    return this.http.put(url, payload, { headers: this.getHeaders() });
  }

  // Exist email check
  checkEmailAvailability(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'public-srv/registration/user/exist/check';
    return this.http.post(url, payload, { headers: this.getHeaders() });
  }

  getWorkflowStatus(trackId: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'workflow-srv/usage/process/status/public/' + trackId;
    return this.http.get(url, { headers: this.getHeaders() });
  }

  validateWorkflowStatus(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'workflow-srv/usage/initiate/v2/workflow';
    return this.http.post(url, payload,{ headers: this.getHeaders() });
  }

  workflowContinue(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    const url = Defaultconfig.GetAuthBaseURL() + 'workflow-srv/usage/initiate/workflow/continue';
    return this.http.post(url, payload, { headers });
  }

  codeVerify(payload: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'verification-srv/accounts/user/verification';
    return this.http.post(url, payload,{ headers: this.getHeaders() });
  }

  // Get default password policy
  generatePasswordBypolicyName(name: any): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'password-policy-srv/password/policy_name/' + `${name}`;
    return this.http.get(url, { headers: this.getHeaders() });
  }
  // Verify ciba code 
  cibaVerify(cibaCode: string): Observable<any> {
    const url = Defaultconfig.GetAuthBaseURL() + 'ciba-srv/validate/' + `${cibaCode}`;
    return this.http.get(url, { headers: this.getHeaders() });
  }
}
