<div id="wrapper" [ngClass]="viewType == 'desktop' ? 'show' : 'hidden'">
  <aside id="sidebar-wrapper">
    <!-- <ng-container *ngIf="viewType != 'mobile'"> -->
      <ul class="sidebar-nav">
        <li>
          <a class="navbar-brand" id="sidebar-toggle">
            <img alt="bars" src="assets/images/apps/bar.svg" />
          </a>
        </li>
        <li class="active">
          <a>
            <img alt="bars" src="assets/images/apps/bar-active.svg" />
            <span>Apps</span>
          </a>
        </li>
        <ng-container *ngIf="apps && apps.length">
          <li *ngFor="let app of apps;">
            <a (click)="redirect(app.login_url)">
              <img [src]="app.image" alt="logo" />
              <span>{{app.name}}</span>
            </a>
          </li>
        </ng-container>
        <li class="footer">
          <a (click)="logout()">
            <img alt="bars" src="assets/images/apps/logout.svg" />
            <span>Logout</span>
          </a>
        </li>
      </ul>
    <!-- </ng-container> -->
  </aside>
  <section id="content-wrapper">
    <div class="min-vh-100">
      <div class="row container justify-content-between p-0">
        <h1 class="profile-subTitle mt-2 ms-2 p-0">Apps</h1>
        <p class="profile-sidemenu ms-2 p-0">Apps Tailored for Your Accessibility</p>
        <hr class="border mt-2" />
        <div class="d-flex flex-wrap gap-4 mt-2">
          <ng-container *ngFor="let app of apps; let i = index">
            <div class="col-md-2 flex-item rounded pointer" [ngClass]="{ 'col-12': viewType == 'mobile' }"
              (click)="redirect(app.login_url)">
              <div class="row align-items-center" ngbDropdown>
                <img *ngIf="app.image" class="col-4" [src]="app.image" alt="Logo" width="35" height="35" />
                <p class="col apps-name m-0">{{ app.name }}</p>
              </div>
            </div>
          </ng-container>
        </div>
        <p class="profile-sidemenu ms-2 p-0 recent">Recently Used Apps</p>
        <hr class="border mt-2" />
        <div class="d-flex flex-wrap gap-4 mt-2">
          <ng-container *ngFor="let app of apps; let i = index">
            <div class="col-md-2 flex-item rounded pointer" [ngClass]="{ 'col-12': viewType == 'mobile' }"
              (click)="redirect(app.login_url)">
              <div class="row align-items-center" ngbDropdown>
                <img *ngIf="app.image" class="col-4" [src]="app.image" alt="Logo" width="35" height="35" />
                <p class="col apps-name m-0">{{ app.name }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>
<ng-container *ngIf="viewType == 'mobile'">
  <section id="content-wrapper">
    <div class="min-vh-100">
      <div class="row container justify-content-center text-center">
        <img [src]="logoUrl" alt="Logo" class="my-4 logo-url m-3" width="150" height="150" />
        <h1 class="profile-subTitle mt-2 ms-2 p-0">Apps</h1>
        <p class="profile-sidemenu ms-2 p-0">Apps Tailored for Your Accessibility</p>
        <!-- <hr class="border mt-2" /> -->
        <div class="row justify-content-center mt-2">
          <ng-container *ngFor="let app of apps; let i = index">
            <div class="col-4 gap-4">
              <div class="d-flex flex-item-mob rounded pointer" (click)="redirect(app.login_url)">
                <img *ngIf="app.image" class="logo" [src]="app.image" alt="Logo" width="35" height="35" />
              </div>
              <p class="apps-name-mob">{{app.name}}</p>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row text-center align-items-center mt-4">
        <p class="bottom-description bottom-lk">
          Do you want to logout?
          <a class="bottom-link cursor-pointer" (click)="logout()">Click Here</a>
        </p>
      </div>
    </div>
  </section>
</ng-container>