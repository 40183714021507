import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  events: Observable<{}> | any;
  eventsSubject: any;
  listeners: any;
  constructor() {
    this.listeners = {};
    this.eventsSubject = new BehaviorSubject<any>({});
    this.events = from(this.eventsSubject);
    this.events.subscribe((params: { name: any; args: any }) => {
      if (this.listeners[params.name]) {
        for (let listener of this.listeners[params.name]) {
          listener(...params.args);
        }
      }
    });
  }

  on(name: string | number, listener: any) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  broadcast(name: any, ...args: any) {
    this.eventsSubject.next({
      name,
      args,
    });
  }

  private publicLoginInfo = new BehaviorSubject<any>('');
  public publicLoginInfo$ = this.publicLoginInfo.asObservable();

  private queryParams = new BehaviorSubject<any>('');
  public queryParams$ = this.queryParams.asObservable();

  private userInfo = new BehaviorSubject<any>('');
  public userInfo$ = this.userInfo.asObservable();

  private providerSettings = new BehaviorSubject<any>('');
  public providerSettings$ = this.providerSettings.asObservable();

  setPublicLoginInfo(data: any) {
    this.publicLoginInfo.next(data);
  }
  setQueryParams(data: any) {
    this.queryParams.next(data);
  }
  setUserInfo(data: any) {
    this.userInfo.next(data);
  }
  setProviderSettings(data: any) {
    this.providerSettings.next(data);
  }
}
